import axiosInstance from 'src/utils/axiosInstance'

const getDashboardDetailsData = async (credentials) => {
  const response = await axiosInstance.get('api/admin/auth/dashboard')
  return response
}

const addRole = async (roleData) => {
  try {
    const response = await axiosInstance.post(`api/role`, roleData)
    return response.data
  } catch (error) {
    throw error
  }
}

const getRolesList = async ({ page, order, orderBy, limit, keyword, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(`api/role${isPaginate ? '?paginate=true' : ''}`, {
      params: { page, order, orderBy, limit, keyword },
    })
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getRoleById = async (roleId) => {
  try {
    const response = await axiosInstance.get(`api/role/${roleId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const settingService = {
  getDashboardDetailsData, // to be removed
  addRole,
  getRolesList,
  getRoleById,
}

export default settingService
