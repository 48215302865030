import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import mastersService from './mastersService'
import { getApiError } from 'src/utils/helper'

const initialState = {
  // Modules
  userRights: null,
  shippingTypesList: [],
  designList: [],
  design: null,
  colorsList: [],
  selectedColor: null,
  crsEntries: [],
  crsSalesRepEntries: [],
  selectedCRSEntry: null,
  customerSalesReps: [],
  selectedCustomerSalesRep: null,
  userList: [],
  selectedUser: null,
  styleList: [],
  selectedStyle: null,
  sizesList: [],
  stitchesList: [],
  colorPricesList: [],
  machinesList: [],
  upcList: [],
  warehouseList: [],
  selectedWarehouse: null,
  selectedSize: null,
  customers: [],
  customerDetails: null,

  // Common
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: '',
  data: [],
  page: 1,
  limit: 10,
  searchKeyword: '',
  isListCompleted: false,
}

// FetchData function for pagination
export const fetchData = createAsyncThunk(
  'pagination/fetchData',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState().pagination
      const response = await mastersService.getColorsList({
        page: state.page,
        limit: state.limit,
        keyword: state.searchKeyword,
        order: 'DESC',
        orderBy: 'createdAt',
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

// Login User
export const getDashboardDetails = createAsyncThunk(
  'dashboard/getDashboardDetails',
  async (userToken, thunkAPI) => {
    try {
      return await mastersService.getDashboardDetailsData(userToken)
    } catch (error) {
      console.debug(error)
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  },
)

// SHIPPING APIs

export const fetchShippingTypes = createAsyncThunk(
  'shippingTypes/fetchShippingTypes',
  async (
    {
      page,
      limit,
      keyword,
      isPaginate = true,
      isFirst = false,
      order = 'DESC',
      orderBy = 'createdAt',
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await mastersService.getShippingTypes({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const addShippingType = createAsyncThunk(
  'shippingTypes/addShippingType',
  async (shippingTypeData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.createShippingType(shippingTypeData)
      dispatch(
        fetchShippingTypes({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateShippingType = createAsyncThunk(
  'shippingTypes/updateShippingType',
  async (shippingTypeData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.updateShippingType(shippingTypeData)
      dispatch(
        fetchShippingTypes({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

// DESIGN APIs

export const fetchDesigns = createAsyncThunk(
  'design/fetchDesigns',
  async (
    {
      page,
      limit,
      keyword,
      isPaginate = true,
      isFirst = false,
      order = 'DESC',
      orderBy = 'createdAt',
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await mastersService.getDesignList({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchDesignById = createAsyncThunk('design/fetchDesignById', async (designId) => {
  return mastersService.getDesignById(designId)
})

export const addDesign = createAsyncThunk(
  'design/addDesign',
  async (designData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.createDesign(designData)
      dispatch(
        fetchDesigns({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

// COLOR APIs

export const createColor = createAsyncThunk(
  'colors/createColor',
  async (colorData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.addColor(colorData)
      dispatch(
        fetchColorsList({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      dispatch(reset())
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchColorsList = createAsyncThunk(
  'colors/fetchColorsList',
  async (
    {
      page,
      limit,
      keyword,
      isPaginate = true,
      isFirst = false,
      order = 'DESC',
      orderBy = 'createdAt',
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await mastersService.getColorsList({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchColorById = createAsyncThunk(
  'colors/fetchColorById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await mastersService.getColorById(id)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

// SALES REP APIs

export const fetchCustomerSalesReps = createAsyncThunk(
  'customerSalesRep/fetchCustomerSalesReps',
  async (
    {
      page,
      limit,
      keyword,
      isPaginate = true,
      isFirst = false,
      order = 'DESC',
      orderBy = 'createdAt',
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await mastersService.getCustomerSalesReps({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const createCustomerSalesRep = createAsyncThunk(
  'customerSalesRep/createCustomerSalesRep',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.createCustomerSalesRep(data)
      dispatch(
        fetchCustomerSalesReps({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const deleteCustomerSalesRep = createAsyncThunk(
  'customerSalesRep/deleteCustomerSalesRep',
  async (id, { rejectWithValue }) => {
    try {
      const response = await mastersService.deleteCustomerSalesRep(id)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

// CRS Entry APIs

export const createCrsEntry = createAsyncThunk(
  'masters/createCrsEntry',
  async (crsEntryData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.createCRSEntry(crsEntryData)
      dispatch(
        fetchCrsEntries({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchCrsEntries = createAsyncThunk(
  'masters/fetchCrsEntries',
  async (
    {
      page,
      limit,
      keyword,
      isPaginate = true,
      isFirst = false,
      order = 'DESC',
      orderBy = 'createdAt',
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await mastersService.listCRSEntries({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchCrsEntryById = createAsyncThunk(
  'masters/fetchCrsEntryById',
  async (data, { rejectWithValue }) => {
    try {
      const response = await mastersService.getCRSEntryById(data?.id, data?.isSalesRep)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

// USER APIs
export const createUser = createAsyncThunk(
  'users/createUser',
  async (userData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.createUser(userData)
      dispatch(
        fetchUsersList({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchUsersList = createAsyncThunk(
  'users/fetchUsersList',
  async (
    {
      page,
      limit,
      keyword,
      isPaginate = true,
      isFirst = false,
      order = 'DESC',
      orderBy = 'createdAt',
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await mastersService.listUsers({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchUserById = createAsyncThunk(
  'users/fetchUserById',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await mastersService.getUserById(userId)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

// Style APIs
export const createStyle = createAsyncThunk(
  'styles/createStyle',
  async (styleData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.createStyle(styleData)
      dispatch(
        fetchStylesList({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateStyle = createAsyncThunk(
  'styles/updateStyle',
  async (styleData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.updateStyle(styleData)
      dispatch(
        fetchStylesList({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchStylesList = createAsyncThunk(
  'styles/fetchStylesList',
  async (
    { page, limit, keyword, orderOnly, isPaginate = true, order = 'DESC', orderBy = 'createdAt' },
    { rejectWithValue },
  ) => {
    try {
      const response = await mastersService.listStyle({
        page,
        limit,
        orderOnly,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchStyleById = createAsyncThunk(
  'style/fetchStyleById',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await mastersService.getStyleById(userId)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

// Size APIs
export const createSize = createAsyncThunk(
  'size/createSize',
  async (sizeData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.createSize(sizeData)
      dispatch(
        fetchSizes({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchSizes = createAsyncThunk(
  'size/fetchSizes',
  async (
    {
      page,
      limit,
      keyword,
      isPaginate = true,
      isFirst = false,
      order = 'DESC',
      orderBy = 'createdAt',
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await mastersService.listSizes({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchUPC = createAsyncThunk(
  'size/fetchUPC',
  async (
    {
      page,
      limit,
      keyword,
      isPaginate = true,
      isFirst = false,
      order = 'DESC',
      orderBy = 'createdAt',
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await mastersService.listUPC({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchWarehouse = createAsyncThunk(
  'size/fetchWarehouse',
  async (
    { page, limit, keyword, isPaginate = true, order = 'DESC', orderBy = 'createdAt' },
    { rejectWithValue },
  ) => {
    try {
      const response = await mastersService.listWarehouse({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchWarehouseById = createAsyncThunk(
  'warehouse/fetchWarehouseById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await mastersService.getWarehouseById(id)
      return response?.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const createWarehouse = createAsyncThunk(
  'size/createWarehouse',
  async (warehouseData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.createWarehouse(warehouseData)
      dispatch(
        fetchWarehouse({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateWarehouse = createAsyncThunk(
  'warehouse/updateWarehouse',
  async (warehouseData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.updateWarehouse(warehouseData)
      dispatch(
        fetchWarehouse({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchStitches = createAsyncThunk(
  'size/fetchStitches',
  async (
    {
      page,
      limit,
      keyword,
      isPaginate = true,
      order = 'DESC',
      orderBy = 'createdAt',
      isFirst = false,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await mastersService.listStitches({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchStitchesById = createAsyncThunk(
  'stitches/fetchStitchesById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await mastersService.getStitchesById(id)
      return response?.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const createStitches = createAsyncThunk(
  'size/createStitches',
  async (stitchesData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.createStitches(stitchesData)
      dispatch(
        fetchStitches({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateStitches = createAsyncThunk(
  'stitches/updateStitches',
  async (stitchesData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.updateStitches(stitchesData)
      dispatch(
        fetchStitches({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchColorPrices = createAsyncThunk(
  'size/fetchColorPrices',
  async (
    {
      page,
      limit,
      keyword,
      isPaginate = true,
      order = 'DESC',
      orderBy = 'createdAt',
      isFirst = false,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await mastersService.listColorPrices({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchColorPricesById = createAsyncThunk(
  'colorPrices/fetchColorPricesById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await mastersService.getColorPricesById(id)
      return response?.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const createColorPrices = createAsyncThunk(
  'size/createColorPrices',
  async (colorPricesData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.createColorPrices(colorPricesData)
      dispatch(
        fetchColorPrices({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateColorPrices = createAsyncThunk(
  'colorPrices/updateColorPrices',
  async (colorPricesData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.updateColorPrices(colorPricesData)
      dispatch(
        fetchColorPrices({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)
export const fetchMachines = createAsyncThunk(
  'size/fetchMachines',
  async (
    {
      page,
      limit,
      keyword,
      isPaginate = true,
      order = 'DESC',
      orderBy = 'createdAt',
      isFirst = false,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await mastersService.listMachines({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchMachineById = createAsyncThunk(
  'machine/fetchMachineById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await mastersService.getMachineById(id)
      return response?.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const createMachine = createAsyncThunk(
  'size/createMachine',
  async (machineData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.createMachine(machineData)
      dispatch(
        fetchMachines({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateMachine = createAsyncThunk(
  'machine/updateMachine',
  async (machineData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.updateMachine(machineData)
      dispatch(
        fetchMachines({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchSizeById = createAsyncThunk(
  'size/fetchSizeById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await mastersService.getSizeById(id)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

// Customer APIs
export const createCustomer = createAsyncThunk(
  'masters/createCustomer',
  async (customerData, { dispatch, rejectWithValue }) => {
    try {
      const response = await mastersService.createCustomer(customerData)
      dispatch(
        fetchCustomers({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchCustomers = createAsyncThunk(
  'masters/fetchCustomers',
  async (
    { page, limit, keyword, isPaginate = true, order = 'DESC', orderBy = 'createdAt' },
    { rejectWithValue },
  ) => {
    try {
      const response = await mastersService.listCustomers({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchCustomerById = createAsyncThunk(
  'masters/fetchCustomerById',
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await mastersService.getCustomerById(customerId)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updatePassword = createAsyncThunk(
  'masters/updatePassword',
  async (userData, { _, rejectWithValue }) => {
    try {
      const response = await mastersService.updatePassword(userData)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

// Function to handle pagination search results
export const handleFulfilledList = (state, action, listStateName) => {
  state.isLoading = false
  state.isError = false

  const isAllFetch = action.meta.arg.isPaginate === false
  const isFirstFetch = action.meta.arg.isFirst
  const hasKeyword = Boolean(action.meta.arg.keyword)

  if (isFirstFetch || isAllFetch) {
    state[listStateName] = action.payload?.data
    state.isListCompleted = action.payload?.data?.length !== action.meta.arg.limit
    return
  }

  if (hasKeyword) {
    if (action.meta.arg.page > 1) {
      const isNewDataAvailable = action.payload?.data?.length > 0
      const isListCompleted = action.payload?.data?.length !== action.meta.arg.limit

      if (isNewDataAvailable) {
        state[listStateName] = [...state[listStateName], ...action.payload.data]
        state.isListCompleted = isListCompleted
      } else {
        state.isListCompleted = true
      }
    } else {
      state[listStateName] = action.payload?.data
      state.isListCompleted = false
    }
  } else {
    const isNewDataAvailable = action.payload?.data?.length > 0
    const isListCompleted = action.payload?.data?.length !== action.meta.arg.limit

    if (isNewDataAvailable) {
      const lastElementId = state[listStateName][state[listStateName].length - 1]?.id
      const lastElement = action.payload?.data[action.payload?.data.length - 1]?.id

      if (lastElementId !== lastElement) {
        state[listStateName] = [...state[listStateName], ...action.payload.data]
      }
      state.isListCompleted = isListCompleted
    } else {
      state[listStateName] = [...state[listStateName], ...action.payload.data]
      state.isListCompleted = true
    }
  }
}

export const mastersSlice = createSlice({
  name: 'masters',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false
      state.isLoading = false
      state.isSuccess = false
      state.page = 1
      state.limit = 10
      state.isListCompleted = false
      state.searchKeyword = ''
      state.message = ''
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setLimit: (state, action) => {
      state.limit = action.payload
    },
    setSearchKeyword: (state, action) => {
      state.searchKeyword = action.payload
    },
    setIsListCompleted: (state, action) => {
      state.isListCompleted = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardDetails.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDashboardDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.dashboardDetails = action.payload?.data?.data
        state.message = action.payload?.message
      })
      .addCase(getDashboardDetails.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload?.message
      })
      // SHIPPING
      .addCase(fetchShippingTypes.pending, (state, action) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = action.payload?.message
      })
      .addCase(fetchShippingTypes.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'shippingTypesList')
      })
      .addCase(fetchShippingTypes.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = action.payload?.message
      })
      .addCase(addShippingType.pending, (state, action) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = action.payload?.message
      })
      .addCase(addShippingType.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.message = action.payload?.message
      })
      .addCase(addShippingType.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = action.payload?.message
      })
      .addCase(updateShippingType.pending, (state, action) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
      })
      .addCase(updateShippingType.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.message = action.payload?.message
      })
      .addCase(updateShippingType.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = action.payload?.message
      })
      // DESIGN
      .addCase(fetchDesigns.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDesigns.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'designList')
      })
      .addCase(fetchDesigns.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload?.message
      })
      .addCase(fetchDesignById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDesignById.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.design = action.payload
      })
      .addCase(fetchDesignById.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload?.message
      })
      .addCase(addDesign.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addDesign.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.message = action.payload?.message
      })
      .addCase(addDesign.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = action.payload?.message
      })
      // COLOR
      .addCase(createColor.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createColor.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.message = action.payload?.message
      })
      .addCase(createColor.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = action.payload?.message
      })
      .addCase(fetchColorsList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchColorsList.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'colorsList')
      })
      .addCase(fetchColorsList.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = action.payload?.message
      })
      .addCase(fetchColorById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchColorById.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.selectedColor = action.payload
      })
      .addCase(fetchColorById.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = action.payload?.message
      })
      // SALES REP
      .addCase(fetchCustomerSalesReps.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCustomerSalesReps.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'customerSalesReps')
      })
      .addCase(fetchCustomerSalesReps.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(createCustomerSalesRep.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createCustomerSalesRep.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.message = action.payload?.message
      })
      .addCase(createCustomerSalesRep.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload?.message
      })
      .addCase(deleteCustomerSalesRep.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteCustomerSalesRep.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.message = action.payload?.message
      })
      .addCase(deleteCustomerSalesRep.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload?.message
      })
      // CRS Entry
      .addCase(createCrsEntry.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createCrsEntry.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.crsEntries.push(action.payload)
        state.message = action.payload?.message
      })
      .addCase(createCrsEntry.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload?.message
      })
      .addCase(fetchCrsEntries.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCrsEntries.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'crsEntries')
      })
      .addCase(fetchCrsEntries.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload?.message
      })
      .addCase(fetchCrsEntryById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCrsEntryById.fulfilled, (state, action) => {
        state.isLoading = false
        // state.isSuccess = true
        // state.message = action.payload?.message
        state.crsSalesRepEntries = action.payload?.data
      })
      .addCase(fetchCrsEntryById.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload?.message
      })
      // Users
      .addCase(createUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload?.message
      })
      // Handling fetch users list
      .addCase(fetchUsersList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUsersList.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'userList')
      })
      .addCase(fetchUsersList.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload?.message
      })
      // Handling fetch user by ID
      .addCase(fetchUserById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.isLoading = false
        state.selectedUser = action.payload
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload?.message
      })
      .addCase(fetchData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.data = state.data.concat(action.payload) // Assuming the API response is an array
        state.page += 1 // Increment the page for pagination
        state.message = action.payload?.message
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = action.payload?.message
      })
      // STYLES CASES
      .addCase(createStyle.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createStyle.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createStyle.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(updateStyle.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = ''
      })
      .addCase(updateStyle.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(updateStyle.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      // Handling fetch style list
      .addCase(fetchStylesList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchStylesList.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'styleList')
      })
      .addCase(fetchStylesList.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      // Handling fetch style by ID
      .addCase(fetchStyleById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchStyleById.fulfilled, (state, action) => {
        state.isLoading = false
        state.selectedStyle = action.payload
      })
      .addCase(fetchStyleById.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      // SIZE
      .addCase(createSize.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createSize.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.sizesList.push(action.payload)
        state.message = action.payload?.message
      })
      .addCase(createSize.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchSizes.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSizes.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'sizesList')
      })
      .addCase(fetchSizes.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchStitches.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchStitches.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'stitchesList')
      })
      .addCase(fetchStitches.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      // .addCase(fetchStitchesById.pending, (state) => {
      //   state.isLoading = true
      //   state.isSuccess = false
      //   state.isError = false
      // })
      // .addCase(fetchStitchesById.fulfilled, (state, action) => {
      //   state.isLoading = false
      //   state.selectedStitches = action.payload
      // })
      // .addCase(fetchStitchesById.rejected, (state, action) => {
      //   state.isLoading = false
      //   state.isError = true
      //   state.message = action.payload?.message
      // })
      .addCase(createStitches.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createStitches.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createStitches.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(updateStitches.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = ''
      })
      .addCase(updateStitches.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(updateStitches.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchColorPrices.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchColorPrices.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'colorPricesList')
      })
      .addCase(fetchColorPrices.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      // .addCase(fetchColorPricesById.pending, (state) => {
      //   state.isLoading = true
      //   state.isSuccess = false
      //   state.isError = false
      // })
      // .addCase(fetchColorPricesById.fulfilled, (state, action) => {
      //   state.isLoading = false
      //   state.selectedColorPrices = action.payload
      // })
      // .addCase(fetchColorPricesById.rejected, (state, action) => {
      //   state.isLoading = false
      //   state.isError = true
      //   state.message = action.payload?.message
      // })
      .addCase(createColorPrices.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createColorPrices.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createColorPrices.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(updateColorPrices.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = ''
      })
      .addCase(updateColorPrices.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(updateColorPrices.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchMachines.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchMachines.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'machinesList')
      })
      .addCase(fetchMachines.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      // .addCase(fetchMachineById.pending, (state) => {
      //   state.isLoading = true
      //   state.isSuccess = false
      //   state.isError = false
      // })
      // .addCase(fetchMachineById.fulfilled, (state, action) => {
      //   state.isLoading = false
      //   state.selectedMachine = action.payload
      // })
      // .addCase(fetchMachineById.rejected, (state, action) => {
      //   state.isLoading = false
      //   state.isError = true
      //   state.message = action.payload?.message
      // })
      .addCase(createMachine.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createMachine.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createMachine.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(updateMachine.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = ''
      })
      .addCase(updateMachine.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(updateMachine.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchUPC.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUPC.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'upcList')
      })
      .addCase(fetchUPC.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchWarehouse.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWarehouse.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'warehouseList')
      })
      .addCase(fetchWarehouse.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchWarehouseById.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
      })
      .addCase(fetchWarehouseById.fulfilled, (state, action) => {
        state.isLoading = false
        state.selectedWarehouse = action.payload
      })
      .addCase(fetchWarehouseById.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload?.message
      })
      .addCase(createWarehouse.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createWarehouse.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createWarehouse.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(updateWarehouse.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = ''
      })
      .addCase(updateWarehouse.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(updateWarehouse.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchSizeById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSizeById.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(fetchSizeById.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      // CUSTOMER
      .addCase(createCustomer.pending, (state, action) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = action.payload?.message
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchCustomers.pending, (state, action) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = action.payload?.message
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'customers')
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchCustomerById.pending, (state, action) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = action.payload?.message
      })
      .addCase(fetchCustomerById.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.customerDetails = action.payload
      })
      .addCase(fetchCustomerById.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(updatePassword.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.isError = false
        state.message = ''
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
  },
})

export const { reset, setPage, setLimit, setSearchKeyword, setIsListCompleted } =
  mastersSlice.actions
export default mastersSlice.reducer
