import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import settingService from './settingService'
import { handleFulfilledList } from '../masters/mastersSlice'
import { getApiError } from 'src/utils/helper'

const initialState = {
  rolesList: [],
  selectedRole: null,
  userRights: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: '',
  data: [],
  page: 1,
  limit: 10,
  searchKeyword: '',
  isListCompleted: false,
}

// Login User
export const getDashboardDetails = createAsyncThunk(
  'dashboard/getDashboardDetails',
  async (userToken, thunkAPI) => {
    try {
      return await settingService.getDashboardDetailsData(userToken)
    } catch (error) {
      console.debug(error)
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const createRole = createAsyncThunk(
  'roles/createRole',
  async (roleData, { dispatch, rejectWithValue }) => {
    try {
      const response = await settingService.addRole(roleData)
      dispatch(
        fetchRolesList({
          page: 1,
          limit: 10,
          keyword: '',
          isFirst: true,
          order: 'DESC',
          orderBy: 'createdAt',
        }),
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchRolesList = createAsyncThunk(
  'roles/fetchRolesList',
  async (
    {
      page,
      limit,
      keyword,
      isPaginate = true,
      isFirst = false,
      order = 'DESC',
      orderBy = 'createdAt',
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await settingService.getRolesList({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchRoleById = createAsyncThunk(
  'roles/fetchRoleById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await settingService.getRoleById(id)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false
      state.isLoading = false
      state.isSuccess = false
      state.message = ''
    },
    setIsListCompleted: (state, action) => {
      state.isListCompleted = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardDetails.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDashboardDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.dashboardDetails = action.payload?.data?.data
        state.message = action.payload?.message
      })
      .addCase(getDashboardDetails.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(createRole.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.message = action.payload?.message
      })
      .addCase(createRole.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchRolesList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchRolesList.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'rolesList')
      })
      .addCase(fetchRolesList.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchRoleById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchRoleById.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.selectedRole = action.payload
      })
      .addCase(fetchRoleById.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
  },
})

export const { reset, setIsListCompleted } = settingSlice.actions
export default settingSlice.reducer
