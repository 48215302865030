import axiosInstance from 'src/utils/axiosInstance'

const updateOrder = async (orderData) => {
  try {
    const response = await axiosInstance.put(`api/order/${orderData?.id}`, orderData)
    return response.data
  } catch (error) {
    throw error
  }
}

const listOrders = async ({
  page,
  order,
  orderBy,
  limit,
  keyword,
  forBatch,
  isPaginate = true,
}) => {
  try {
    const response = await axiosInstance.get(`api/order${isPaginate ? '?paginate=true' : ''}`, {
      params: { page, order, orderBy, limit, keyword, forBatch },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const getOrderById = async (orderId) => {
  try {
    console.debug({ orderId })
    const response = await axiosInstance.get(`api/order/${orderId}`)
    return response.data?.data
  } catch (error) {
    throw error
  }
}

const getOrderStyles = async (orderNo) => {
  try {
    const response = await axiosInstance.get(`api/order/${orderNo}/styles`)
    return response.data?.data
  } catch (error) {
    throw error
  }
}

const createProcess = async (processData) => {
  try {
    const response = await axiosInstance.post(`api/process`, processData)
    return response.data
  } catch (error) {
    throw error
  }
}

const updateProcessPublish = async (processData) => {
  try {
    const response = await axiosInstance.put(`api/process/publish`, processData)
    return response.data
  } catch (error) {
    throw error
  }
}

const getProcessQuantityList = async ({ orderId, styleId, colorId, sizeId }) => {
  try {
    const response = await axiosInstance.get(
      `api/process?orderId=${orderId}&styleId=${styleId}&colorId=${colorId}&sizeId=${sizeId}`,
    )
    return response.data?.data
  } catch (error) {
    throw error
  }
}

const getProcessOrders = async (processOrderType) => {
  try {
    const response = await axiosInstance.get(`api/process/orders?type=${processOrderType}`)
    return response.data?.data
  } catch (error) {
    throw error
  }
}

const getProcessOrdersList = async () => {
  try {
    const response = await axiosInstance.get(`api/process/process-orders`)
    return response.data?.data
  } catch (error) {
    throw error
  }
}

const getProcessAssociations = async ({ type = 'style', orderId, styleId, colorId }) => {
  let url = `api/process/associations/${type}?orderId=${orderId}`
  if (type === 'color') url = `${url}&styleId=${styleId}`
  if (type === 'size') url = `${url}&styleId=${styleId}&colorId=${colorId}`

  try {
    const response = await axiosInstance.get(url)
    return response.data?.data
  } catch (error) {
    throw error
  }
}

const getOrderQuantities = async ({ styleId, colorId }) => {
  try {
    const response = await axiosInstance.get(
      `api/pallet/order-quantities?styleId=${styleId}&colorId=${colorId}`,
    )
    return response.data
  } catch (error) {
    throw error
  }
}

const createReturnProcess = async (processData) => {
  try {
    const response = await axiosInstance.post(`api/process/return-processes`, processData)
    return response.data
  } catch (error) {
    throw error
  }
}

const listReturnProcess = async ({
  page,
  order,
  orderBy,
  limit,
  keyword,
  forBatch,
  isPaginate = true,
}) => {
  try {
    const response = await axiosInstance.get(
      `api/process/return-processes${isPaginate ? '?paginate=true' : ''}`,
      {
        params: { page, order, orderBy, limit, keyword, forBatch },
      },
    )
    return response.data
  } catch (error) {
    throw error
  }
}

const getReturnProcessById = async (processOrderId) => {
  try {
    const response = await axiosInstance.get(`api/process/return-processes/${processOrderId}`)
    return response.data?.data
  } catch (error) {
    throw error
  }
}

const getPalletOrderQuantities = async ({ palletId, styleId, colorId }) => {
  try {
    const response = await axiosInstance.get(
      `api/pallet/${palletId}?styleId=${styleId}&colorId=${colorId}&forTransaction=true`,
    )
    return response.data
  } catch (error) {
    throw error
  }
}

const getProcessOrderById = async (processOrderId, type) => {
  try {
    console.debug({ processOrderId, type })
    const response = await axiosInstance.get(
      `api/process/process-order/${processOrderId}?type=${type}`,
    )
    return response.data?.data
  } catch (error) {
    throw error
  }
}

const getUPCMatrix = async (orderId, styleId, colorId, designId) => {
  try {
    const response = await axiosInstance.get(
      `api/order/upc-matrix?orderId=${orderId}&styleId=${styleId}&colorId=${colorId}&designId=${designId}`,
    )
    return response.data?.data
  } catch (error) {
    throw error
  }
}

const createUPC = async (upcData) => {
  try {
    const response = await axiosInstance.post(`api/upc`, upcData)
    console.debug({ response })
    return response.data
  } catch (error) {
    throw error
  }
}

const listBatch = async ({ page, order, orderBy, limit, keyword, forBatch, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(`api/batch${isPaginate ? '?paginate=true' : ''}`, {
      params: { page, order, orderBy, limit, keyword, forBatch },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const createBatch = async (batchData) => {
  try {
    const response = await axiosInstance.post(`api/batch`, batchData)
    return response.data
  } catch (error) {
    throw error
  }
}

const listImportLogs = async ({ page, order, orderBy, limit, keyword, forBatch, isPaginate }) => {
  try {
    const response = await axiosInstance.get(
      `api/import-logs?action=pallet-entry&type=failed${isPaginate ? '&paginate=true' : ''}`,
      {
        params: { page, order, orderBy, limit, keyword, forBatch },
      },
    )
    return response.data
  } catch (error) {
    throw error
  }
}

const listPallet = async ({
  page,
  order,
  orderBy,
  limit,
  keyword,
  forBatch,
  isPaginate = true,
}) => {
  try {
    const response = await axiosInstance.get(`api/pallet${isPaginate ? '?paginate=true' : ''}`, {
      params: { page, order, orderBy, limit, keyword, forBatch },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const getPalletById = async ({ palletId }) => {
  try {
    const response = await axiosInstance.get(`api/pallet/${palletId}`)
    return response.data?.data
  } catch (error) {
    throw error
  }
}

const updatePallet = async (palletData) => {
  const { id, styles } = palletData
  try {
    const response = await axiosInstance.put(`api/pallet/${id}`, { styles })
    return response.data
  } catch (error) {
    throw error
  }
}

const listPalletStyles = async ({ palletId }) => {
  try {
    const response = await axiosInstance.get(`api/pallet/${palletId}/styles`, {
      params: {},
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const listPalletStyleColors = async ({ palletId, styleId }) => {
  try {
    const response = await axiosInstance.get(`api/pallet/${palletId}/${styleId}/colors`, {
      params: {},
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const createPallet = async (palletData) => {
  try {
    const response = await axiosInstance.post(`api/pallet`, palletData)
    return response.data
  } catch (error) {
    throw error
  }
}

const createPalletImport = async (palletData) => {
  try {
    const response = await axiosInstance.post(`api/pallet/import`, palletData)
    return response.data
  } catch (error) {
    throw error
  }
}

const createPalletTransaction = async (transactionData) => {
  try {
    const response = await axiosInstance.put(`api/pallet/transaction`, transactionData)
    return response.data
  } catch (error) {
    throw error
  }
}

const createPalletMoveAndUpdate = async (moveAndUpdateData) => {
  try {
    const response = await axiosInstance.put(`api/pallet/move-and-update`, moveAndUpdateData)
    return response.data
  } catch (error) {
    throw error
  }
}

const editBatch = async (batchData) => {
  try {
    const response = await axiosInstance.put(`api/batch/${batchData?.batchId}`, batchData)
    return response.data
  } catch (error) {
    throw error
  }
}

const listCountry = async () => {
  try {
    const response = await axiosInstance.get(`api/country`)
    return response.data
  } catch (error) {
    throw error
  }
}

const transactionService = {
  updateOrder,
  listOrders,
  getOrderStyles,
  getUPCMatrix,
  createUPC,
  listBatch,
  createBatch,
  editBatch,
  listCountry,
  getProcessOrders,
  getProcessOrdersList,
  getProcessAssociations,
  createProcess,
  createReturnProcess,
  listReturnProcess,
  getReturnProcessById,
  updateProcessPublish,
  getOrderById,
  getProcessOrderById,
  createPallet,
  createPalletTransaction,
  getOrderQuantities,
  getPalletOrderQuantities,
  listImportLogs,
  listPallet,
  getPalletById,
  updatePallet,
  listPalletStyles,
  listPalletStyleColors,
  createPalletMoveAndUpdate,
  createPalletImport,
  getProcessQuantityList,
}

export default transactionService
