import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/auth/authSlice'
import dashboardReducer from './features/dashboard/dashboardSlice'
import customerReducer from './features/customer/customerSlice'
import settingReducer from './features/setting/settingSlice'
import mastersReducer from './features/masters/mastersSlice'
import transactionReducer from './features/transaction/transactionSlice'
import reportReducer from './features/report/reportSlice'

const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    customer: customerReducer,
    setting: settingReducer,
    masters: mastersReducer,
    transaction: transactionReducer,
    report: reportReducer,
  },
})

export default store
