import axios from 'axios'
// import jwt_decode from 'jwt-decode';
// import dayjs from 'dayjs'

export const baseURL = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_SERVER_LINK}/`

const getAuthTokens = () => {
  const authTokens = localStorage.getItem('authTokens')
  return authTokens ? JSON.parse(authTokens) : null
}

const axiosInstance = axios.create({ baseURL })

axiosInstance.interceptors.request.use(async (req) => {
  const authTokens = getAuthTokens()

  if (!authTokens) {
    // Handle the case when authTokens are not available (e.g., user not logged in)

    localStorage.removeItem('authTokens')
    localStorage.removeItem('user')
    window.location.replace('/')

    // return req
  }

  // const user = jwt_decode(authTokens?.access);
  // const isExpired = dayjs.unix(authTokens.expiresIn).diff(dayjs()) < 1
  const isExpired = new Date() > new Date(authTokens.expiresIn)

  if (!isExpired) {
    req.headers.Authorization = `Bearer ${authTokens.access}`
    return req
  }

  console.debug({ authTokens })

  try {
    const response = await axios.post(`${baseURL}api/auth/refresh`, {
      // refreshToken: authTokens.refresh,
      token: authTokens.refresh,
    })

    const auth = {
      expiresIn: response.data?.data.expiresIn,
      access: response.data?.data.accessToken,
      refresh: response.data?.data.refreshToken,
    }

    localStorage.setItem('authTokens', JSON.stringify(auth))
    req.headers.Authorization = `Bearer ${auth?.access}`
    return req
  } catch (error) {
    // Handle the error (e.g., redirect to login page)

    localStorage.removeItem('authTokens')
    localStorage.removeItem('user')
    window.location.replace('/')
    return req
  }
})

export default axiosInstance
