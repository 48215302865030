import axios from 'axios'
import axiosInstance from 'src/utils/axiosInstance'
import { baseURL } from 'src/utils/axiosInstance'

//const baseURL = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_SERVER_LINK}/`

const login = async (credentials) => {
  const response = await axios.post(`${baseURL}api/auth/login`, credentials)
  if (response) {
    console.debug('response', response)
    const authToken = {
      access: response?.data?.data?.accessToken,
      refresh: response?.data?.data?.refreshToken,
      expiresIn: response?.data?.data?.expiresIn,
    }
    const user = {
      user: response?.data?.data?.user,
      scopes: response?.data?.data?.scopes,
    }
    localStorage.setItem('authTokens', JSON.stringify(authToken))
    localStorage.setItem('user', JSON.stringify(user))
  }
  return response?.data
}

const logout = async () => {
  const response = await axiosInstance.post(`${baseURL}api/auth/logout`)

  return response?.data
}

const dummyLogin = async (credentials) => {
  if (credentials?.username === 'admin' && credentials?.password === 'admin') {
    return { ...credentials }
  }
  throw new Error('Invalid credentials') // Throw an error for the "else" case
}

const authService = {
  login,
  logout,
  dummyLogin,
}

export default authService
